import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
export default function Solution() {

    const dataList = [{ sequence: '1.', fname: 'Google', sname: 'Ads ', discription: 'Attract fans with Google Ads campaigns, getting their attention and clicks to your music' },
    { sequence: '2.', fname: 'Meta', sname: 'Ads ', discription: 'Reach diverse audience by showcasing music on Facebook, Instagram, etc through Meta Ads.' },
    { sequence: '3.', fname: 'Programmatic ', sname: 'Ads', discription: 'Optimize your music promotions by targeting the right people with data for maximum engagement.' },
    { sequence: '4.', fname: 'Communities', sname: 'Engagements', discription: 'Connect with music artists in your genre and build loyalty. Grow your fan base.' },]

    return (
        <Box className=' commonBg marginTop5'>
            <Box className='container'>
                <Box className='solution-container'>
                    <Typography>Our Solution</Typography>
                    <Typography variant='span' pt={2}>Amplify Your Audience Reach with GrooveNexus Solutions: Tailored Strategies for Music Success</Typography>
                </Box>
                <Box className='marginTop3'>
                    <Grid container columns={{ xs: 12, sm: 6, md: 12 }}>
                        {
                            dataList.map((d, i) => (
                                <Grid key={i} item xs={12} sm={6} md={3} >
                                    <Box className='solution-grid box-ad-style'>
                                        <Typography variant='span' className='textgradient'>{d.sequence}</Typography>
                                        <Box>
                                            <Typography >{d.fname}</Typography>
                                            <Typography >{d.sname}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className='marginTop3'>
                                        <Typography className='banner-subtext font16'>
                                            {d.discription}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Box>

        </Box>
    );
}
