import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import bannerImage from '../img/banner-img.png'
import adwords from '../img/adwords.svg'
import Analysis from '../img/Analysis.svg'
import TrendingUp from '../img/trending-up.svg'
import { Button, Divider, Typography } from '@mui/material';
import ContactUs from './ContactUs';
import { useMediaQuery } from '@mui/material';
export default function Banner() {
    const smScreen = useMediaQuery('(max-width:600px)');
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                <Grid item xs={12} sm={12} md={12} lg={6} >
                    <Box>
                        <Typography variant='h5' className='banner-text'>
                            Make <Typography variant='span'> Your Music </Typography >a <Typography variant='span'> Success </Typography>
                            with <Typography variant='span'> Right Marketing !</Typography>
                        </Typography>
                        <Typography sx={{ padding: '5px 0px 20px 0px' }} className='banner-subtext font18'>Promote your music using genuine Ads.</Typography>
                        <Button onClick={handleClickOpen} className='header-btn font14'>Book a free call with us</Button>
                    </Box>
                    <Box sx={{ position: 'relative' }}>
                        <Box className='banner-bottom-text marginTop5'>
                            <img src={TrendingUp} style={{
                                display: smScreen ? 'none' : '',
                                position: 'absolute',
                                right: 0,
                                fontSize: '45px'
                            }} />
                            <Grid sx={{ height: smScreen ? 'auto' : 200, gap: smScreen ? '15px' : '' }} container alignItems={'center'} columns={{ xs: 12, sm: 12, md: 12 }}>
                                <Grid item sx={{ padding: '0px 20px' }} xs={12} sm={6} md={6} >
                                    <Box className='box-ad-style'>
                                        <img src={adwords} alt='adwords' />
                                        <Typography>Certified
                                            Google Ads Partner</Typography>
                                    </Box>
                                </Grid>
                                <Grid item sx={{ padding: '0px 20px' }} xs={12} sm={6} md={6} >
                                    <Box className='box-ad-style'>
                                        <Typography variant='span'>
                                            50+</Typography> <Typography sx={{ width: '60%' }}> Worked with
                                                Labels & Artist</Typography>
                                    </Box>
                                </Grid>
                                <Divider sx={{ width: '100% ', backgroundColor: '#000' }} />
                                <Grid item sx={{ padding: '0px 20px' }} xs={12} sm={6} md={6} >
                                    <Box className='box-ad-style'>
                                        <img src={Analysis} alt='Analysis' />
                                        <Typography>Report
                                            Transparency</Typography>
                                    </Box>
                                </Grid>
                                <Grid item sx={{ padding: '0px 20px' }} xs={12} sm={6} md={6} >
                                    <Box className='box-ad-style'>
                                        <Typography variant='span'>
                                            11.1 B+</Typography> <Typography> Ads Impressions</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className='circle'></Box>
                    </Box>
                </Grid>
                <Grid className='bannerImg' item sx={{ padding: '0px 20px' }} xs={12} sm={12} md={12} lg={6} >
                    <Box sx={{ textAlign: 'right' }}>
                        <img src={bannerImage} alt='bannerImage' />
                    </Box>
                </Grid>
            </Grid>
            <ContactUs open={open} setOpen={setOpen} reset={false} />
        </Box >
    );
}
