import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box, Button, DialogContent, IconButton, TextField, Typography } from '@mui/material';
import { useState, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import emailjs from 'emailjs-com';
import Snackbar from './snackbar';

export default function ContactUs({ open, setOpen, reset }) {
    const [openSnack, setOpenSnack] = React.useState(false);
    const [disableValue, setDisableValue] = React.useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const smScreen = useMediaQuery('(max-width:600px)');
    const [snackMessage, setSnackMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [formData, setFormData] = React.useState({
        name: '',
        email: '',
        link: '',
        contact: '',
        enquiry: '',
    });
    const formRef = useRef();  // Create a form reference

    const handleClose = () => {
        setOpen(false);
    };

    const styling = {
        margin: '10px 0px',
    };

    function handleChange(event) {
        const { name, value } = event.target;
        const fields = ['name', 'email', 'contact', 'link', 'enquiry'];

        setFormData({
            ...formData,
            [name]: value,
        });

        const allFieldsFilled = fields.every(field => formData[field] && formData[field].trim() !== '' && (field !== name || value.trim() !== ''));

        setDisableValue(!allFieldsFilled);

        if (event.type === 'blur' && name === 'email') {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            setEmailValid(emailPattern.test(value));
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.debug("contactus: submit", formRef.current, formData);

        // Send the form using the form reference
        emailjs.sendForm('service_daj4vrj', 'template_9om48ck', formRef.current, '-z__4wjPqbGL513C-')
            .then((result) => {
                setFormData({
                    name: '',
                    email: '',
                    link: '',
                    contact: '',
                    enquiry: '',
                });
                setSnackMessage('Thank you for reaching out to us! We appreciate your interest, we will get back to you soon.');
                setOpenSnack(true);
                setSeverity('success');
                handleClose()
            })
            .catch((error) => {
                setOpenSnack(true);
                setSnackMessage('Error sending email');
                setSeverity('error');
                console.log(error.text);
            });
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '40px',
                },
            }}
        >
            <Box>
                <Box sx={{ background: '#F6F5F5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '40px' }}>
                    <DialogTitle sx={{ fontSize: smScreen ? '20px' : '25px' }}>Contact Us
                        <Typography sx={{ fontSize: smScreen ? '12px' : '14px', width: smScreen ? '85%' : '100%' }}>We’d love to hear from you! Write to us.</Typography>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 30,
                            fontSize: '3rem',
                        }}
                    >
                        <CloseIcon sx={{ fontSize: '3rem', cursor: 'pointer' }} />
                    </IconButton>
                </Box>
                <DialogContent>
                    <Box className="contact-us">
                        <form ref={formRef} onSubmit={handleSubmit}> {/* Form starts here */}
                            <TextField
                                required
                                variant="standard"
                                sx={{ margin: styling.margin }}
                                fullWidth
                                label="Full Name"
                                name="name"
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                variant="standard"
                                sx={{ margin: styling.margin }}
                                fullWidth
                                label="Email"
                                type="email"
                                name="email"
                                error={!emailValid}
                                helperText={!emailValid ? <Typography className="font14">Please enter a valid email.</Typography> : ""}
                                onChange={handleChange}
                                onBlur={handleChange}
                            />
                            <TextField
                                required
                                variant="standard"
                                sx={{ margin: styling.margin }}
                                fullWidth
                                label="Phone Number"
                                type="number"
                                name="contact"
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                variant="standard"
                                sx={{ margin: styling.margin }}
                                fullWidth
                                label="YT Channel link"
                                name="link"
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                variant="standard"
                                sx={{ margin: styling.margin }}
                                fullWidth
                                label="Message/Enquiry"
                                name="enquiry"
                                onChange={handleChange}
                            />
                            <Box sx={{ margin: '15px 0px', textAlign: 'right' }}>
                                <Button type="submit" disabled={disableValue} sx={{ width: '100px' }} className="header-btn font14">Submit</Button>
                            </Box>
                        </form> {/* Form ends here */}
                    </Box>
                </DialogContent>
            </Box>
            <Snackbar severity={severity} open={openSnack} setOpen={setOpenSnack} snackMessage={snackMessage} />
        </Dialog>
    );
}
