import * as React from 'react';
import Box from '@mui/material/Box';
import campaign from '../img/campaign.png'
import { Button, Divider, Grid, Typography } from '@mui/material';
import stepper from '../img/stepper.png'
export default function Campaign() {

    const dataList = [{ sequence: 'Step 1', campaignLabel: 'Request generated by user.' },
    { sequence: 'Step 2', campaignLabel: 'Campaign planning after brainstorming by our digital team & musicians.' },
    { sequence: 'Step 3', campaignLabel: 'Campaign plan defined and execution by expert team. ' },
    { sequence: 'Step 4', campaignLabel: 'Boost your brand with advertising pros.' },]

    return (
        <Box className=''>
            <Box className='solution-container'>
                <Typography>Campaign Process </Typography>
                <Typography variant='span' pt={2}>From Idea to Impact, Let Our Experts Guide Your Campaign Journey.</Typography>
            </Box>
            <Box className='marginTop3 campaign-responsive' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box className=''>
                    <img src={campaign} alt='adImg' />
                </Box>
                <Box sx={{ textAlign: 'center', paddingTop: '50px', height: '410px' }}>
                    <img src={stepper} style={{ height: '100%' }} />
                </Box>
                <Box>
                    {
                        dataList.map((d, i) => (
                            <Box sx={{ display: 'flex', marginTop: '20px' }} key={i}>
                                <Box className='campaign-right-grid'>
                                    <Typography className='stepper textgradient'>{d.sequence}</Typography>
                                    <Typography variant='span' >{d.campaignLabel}</Typography>
                                </Box>
                            </Box>
                        ))
                    }
                </Box>
            </Box>

            {/* </Grid>
            </Grid> */}
        </Box >
    );
}