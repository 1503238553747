import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import FHighEntertainment from '../img/sliderimg/FHighEntertainment.png'
import LoopBeatRecords from '../img/sliderimg/LoopBeatRecords.png'
import Sarvinarck from '../img/sliderimg/Sarvinarck.png'
import SkyMusicRecords from '../img/sliderimg/SkyMusicRecords.png'
import SwattrexMusic from '../img/sliderimg/SwattrexMusic.png'
import vivek from '../img/sliderimg/vivek.png'
import SurmayiRecords from '../img/sliderimg/SurmayiRecords.png'
import garagegully from '../img/sliderimg/garagegully.png'
import garagegullybhojpuri from '../img/sliderimg/garagegullybhojpuri.png'
import GarageGullyRecords from '../img/sliderimg/GarageGullyRecords.png'
import NadarSandhu from '../img/sliderimg/NadarSandhu.png'
import indradhanush from '../img/sliderimg/indradhanush.png'
import ShubhLabhFilms from '../img/sliderimg/ShubhLabhFilms.png'
import apnidhun from '../img/sliderimg/apnidhun.png'
import Marquee from "react-fast-marquee";

export default function Portfolio() {

    const slidShow = [
        { label: 'Sarvinarck ', slidimg: Sarvinarck },
        { label: 'Sky ', slidename: 'Music Records ', slidimg: SkyMusicRecords },
        { label: 'Surmayi ', slidename: 'Records ', slidimg: SurmayiRecords },
        { label: 'Swattrex ', slidename: 'Music ', slidimg: SwattrexMusic },
        { label: 'FHigh ', slidename: 'Entertainment ', slidimg: FHighEntertainment },
        { label: 'Loop ', slidename: 'Beat Records ', slidimg: LoopBeatRecords },
        { label: 'Garage Gully ', slidename: 'Haryanvi', slidimg: garagegully },
        { label: 'Garage Gully  ', slidename: 'Bhojpuri', slidimg: garagegullybhojpuri },
        { label: 'Garage Gully  ', slidename: 'Records', slidimg: GarageGullyRecords },
        { label: 'Nadar ', slidename: 'Sandhu', slidimg: NadarSandhu },
        { label: 'Indradhanush ', slidename: '', slidimg: indradhanush },
        { label: 'Shubh  ', slidename: 'Labh Films', slidimg: ShubhLabhFilms },
        { label: 'ApniDhun  ', slidename: '', slidimg: apnidhun },
    ]

    return (
        <Box className='portfolio' sx={{padding: '65px 0px'}}>
            <Box className='portfolio-container App '>
                <Typography>Portfolio/Labels Connected</Typography>
                <Typography variant='span' pt={2} pb={2}>With GrooveNexus’ Genuine Ads Services Every Label Finds Its Beat</Typography>
            </Box>
            <Marquee pauseOnHover>
                {slidShow.map((d, i) => (
                    <Box className='marquee-slider' key={i}>
                        <Typography className='font16'>  {d.label} </Typography>
                        <Typography className='font16' pb={1}>  {d.slidename} </Typography>
                        <img src={d.slidimg} alt={d.slidimg} />
                    </Box>
                ))}
            </Marquee>
        </Box>
    );
}
